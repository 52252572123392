export default {
    "alesanchezr": {
        "bio": "Amazing dksaflkhadsfglkasklgbkjlgsafbkjlgfsbklgfadsbklj",
        "name": "Alejandro Sanchez",
        "avatar": "/staff/alejandro.png",
        "username": "alesanchezr"
    },
    "MRiciglianoC": {
        "bio": "Amazing dksaflkhadsfglkasklgbkjlgsafbkjlgfsbklgfadsbklj",
        "name": "Marcelo Ricigliano",
        "avatar": "/staff/marcelo.png",
        "username": "MRiciglianoC"
    },
    "4GeeksAcademy": {
        "bio": "Amazing dksaflkhadsfglkasklgbkjlgsafbkjlgfsbklgfadsbklj",
        "name": "4Geeks Academy",
        "avatar": "/4geeks-logo.png",
        "username": "4GeeksAcademy"
    }
}